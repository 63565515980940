.field {
  height: 38px;
  width: 100%;
  position: relative;
  margin-top: 6px;
  padding-bottom: 8px;
}

.field label {
  position: absolute;
  padding: 8px;
  transition: 0.2s;
  color: #666666;
  font-size: 15px;
  pointer-events: none;
}

.field.is-focused label:not(.caret), 
.field.is-dirty label:not(.caret) {
  font-size: 12px;
  padding: 0 11px;
}
.main.aop .field.is-focused input, .main.aop .field.is-focused select{
  border: 1px solid #0077bf;
}
.main.aop .field.is-focused .expanding-line{
  background: #0077bf;
}
.main.aop .field input, .main.aop .field select {
  color: #313131;
}
.main.aop .dropdown.field .caret{
  top: 9px;
  right: 9px;
}

.main.aop .dropdown.field .caret::after{
  color: #313131;
  font-size: 10px;
}
.main.aop .field.is-focused label{
  color: #313131;
}

.field.is-focused label {
  color: #009688;
}

.field input, .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e0e0e0;
  height: 23px;
  padding-left: 10px;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(102, 102, 102);
  transition: border 0.2s ease-in-out;
  background-color: #fafafa;
}

.field input {
  box-sizing: border-box;
  height: 38px;
}

.field select {
  height: 38px;
}

.field select:disabled, .field input:disabled {
  background-color: #efefef;
}

.field.is-disabled label {
  color: darkgray;
}

.field.is-focused input, .field.is-focused select {
  border: 1px solid #5e9edc;
  outline: 0;
  box-shadow: none;
  transition-duration: .3s;
}

.field.is-focused.has-error input, .field.is-focused.has-error select {
  border: 1px solid #f44336;
}

.field .expanding-line {
  content: '';
  position: absolute;
	display: block;
	margin: auto;
	height: 2px;
  width: 0px;
  margin-top: -3px;
  left: 0;
  right: 0;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.field.is-focused .expanding-line {
  width: calc(100% - 3px);
  background: #5e9edc;
}

.field.has-error .expanding-line {
  background: #f44336;
}

.validation {
  position: absolute;
  margin-bottom: 3px;
  color: #d9534f;
  margin-left: 8px;
  position: static;
  display: block;
  font-size: 10px;
  font-weight: 500;
}

.dropdown.field .caret {
  padding: 0;
  color: #000;
  position: absolute;
  right: 15px;
  top: 20%;
  transform: scaleX(1.5);
  pointer-events: none;
}

.dropdown.field .caret::after {
  content:"\25BC";
  font-size: 8px;
}

/* turn off default mozilla invalid style */
input:invalid, select:invalid {
  box-shadow: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.main.aop .field label{
  
}