section#funding-methods {
  display: flex;
  justify-content: space-between;
}

section#funding-methods a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  width: 20%;
}

section#funding-methods div {
  flex: 1;
  box-sizing: inherit;
  margin-right: 0.5em;
  padding: 0.25em;
  border: 1px solid #e0e0e0;
  text-align: center;
  font-size: 10pt;
  height: 18em;
}

section#funding-methods a:last-child div {
  margin-right: 0;
}

section#funding-methods div.description {
  border: none;
  margin: 0;
  height: auto;
  padding: 0;
}

section#funding-methods img {
  max-width: 50px;
  margin: 0.5em 0;
}

section#funding-methods h3 {
  color: rgba(102, 102, 102, 0.7);
  margin-top: 0;
}

section#funding-methods h4 {
  color: #0077BF
}

@media only screen and (max-width : 767px)  {
  .amount-validation{
    font-size: 9px;
  }
  section#funding-methods {
    display: block;
  }

  section#funding-methods div {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5em;
    text-align: left;
    padding: 0.5em 0.5em;
    display: flex;
    align-items: flex-start;
    height: auto;
  }

  section#funding-methods div.description {
    display: block;
    margin-left: 0.5em;
  }

  section#funding-methods img {
    display: inline;
    max-width: 25px;
    margin: 0;
  }
  .main.aop section#funding-methods img {
    max-width: 35px;
  }

  section#funding-methods h3 {
    margin: 0;
    display: inline;
  }

  section#funding-methods h4 {
    margin: 0;
  }

  section#funding-methods p {
    margin: 0;
  }

  section#funding-methods div#transfer-account {
    display: none;
  }
}
