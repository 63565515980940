body, html {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #616161;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 20pt;
  font-weight: 200;
  color: #084497;
  font-weight: bold;
}
.main.aop h1{
  color: #150958;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.main.aop h6{
  font-size: 14px;
  font-weight: 300;
  line-height: 1.42857143;
  color: #333;
}
h2, h3, h4, h5, h6 {
  color: #424242;
}

a {
  color: #0077BF;
  font-style: normal;
  font-weight: 700;
}

.main.aop a{
  color: #0089cf;
}

div.main {
  max-width: 970px;
  margin: 0 15px;
  margin-bottom: 15px;
}
div.main.aop{
  margin: 0 auto !important; 
  font-size: 13px;
  font-weight: 400;
  font-family: "Hind", sans-serif;
  color: #757575;
}

.main.aop .container-fluid{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

hr {
  border: none;
  border-top: 1px solid #e0dddc;
}

address {
  color: #0077BF;
}

ol li {
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  div.main {
    margin: 0 30px;
  }
}

@media screen and (min-width: 970px) {
  div.main {
    margin: 0 auto;
  }
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
  max-height: 0;
}

.page-nav h1, .page-head h1 {
  margin-bottom: 2px;
}

.page-head h1 {
  margin-top: 14px;
  text-align: center;
}

@media screen and (max-width: 667px) {
  .page-head h1 {
    font-size: 1.25rem;
  }
}

.page-head h6 {
  font-size: 12px;
  font-weight: 300;
  margin: 0 0 15px 0;
}

.actions {
  display: flex;
  margin: 8px 0;
}

@media screen and (max-width: 667px) {
  .actions {
    justify-content: center;
  }
}

.actions > div {
  width: 50%;
  margin: 5px;
  max-width: 280px;
}

.actions button, .actions .btn {
  width: 100%;
  text-align: center;
}

.actions > div:first-child {
  margin-left: 0;
}

.actions > div:last-child {
  margin-right: 0;
}

#fees {
  margin-bottom: 0px !important;
}


.main.aop .fee-schedule {
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  border-radius: 2px;
}
.main.aop .fee-schedule div{
  padding: 0px 3px;
}
.main.aop .fee-schedule .separator {
  border: 1px solid #e7e7e7;
}
.fee-schedule {
  display: flex;
  justify-content: space-around;
  background: #eee;
  border: 1px solid black;
}

.fee-schedule dl {
  font-size: 14px;
  margin: 3px;
}

.fee-schedule dl:first-child {
  min-width: 60px;
}

.fee-schedule dt {
   display: inline;
}

.fee-schedule dd {
  display: inline;
  color: #5e9edc;
  margin: 0 0 0 3px;
}

.fee-schedule .separator {
  border-left: 1px solid black;
  margin: 2px 0px;
}

.fee-schedule div {
  margin-bottom: 0px;
}

@media screen and (max-width: 375px) {
  .fee-schedule dl {
    font-size: 10px;
  }
}

option:empty {
  display: none;
}

.amount {
  display: flex;
  justify-content: space-between;
}

.amount .text.field {
  margin-right: 10px;
  flex: 2;
}

.amount .dropdown.field {
  width: 30%;
  min-width: 120px;
}

@media only screen and (max-width : 767px)  {
  #availability {
    width: 100%;
  }
  #metrics {
    margin-left: -8% !important;
  }
  #metrics td {
    font-size: 0.9em;
  }
}

@media only screen and (max-width : 620px)  {
  #metrics {
    margin-left: -7% !important;
  }
  #metrics td {
    font-size: 0.9em;
  }
}

@media only screen and (max-width : 520px)  {
  #metrics {
    margin-left: -8% !important;
  }
  #metrics td {
    font-size: 0.8em;
  }
}

@media only screen and (max-width : 460px)  {
  #metrics {
    margin-left: -6% !important;
  }
  #metrics td {
    font-size: 0.7em;
  }
}

@media only screen and (max-width : 420px)  {
  .field select {
    font-size: 1em;
  }
  #donut canvas {
    /* height: 130px !important; */
  }
  #metrics {
    margin-left: -6% !important;
  }
  #metrics td {
    font-size: 0.7em;
  }
}

@media only screen and (max-width : 320px)  {
  .field select {
    font-size: 0.8em;
  }
  #donut canvas {
    /* height: 120px !important; */
  }
  #metrics {
    margin-left: -8% !important;
    /* margin-top: 0px !important; */
  }
  #metrics td {
    font-size: 0.6em;
  }
}

#loader {
  overflow: hidden;
  margin: 3em auto;
  border: 16px solid #EEE;
  border-top: 16px solid rgb(94, 158, 220);
  border-radius: 50%;
  width: 7em;
  height: 7em;
  animation: spin 1s linear infinite;
}.main.aop #loader{
  border-top: 6px solid #268BC9;
  border-width: 6px
}

.loader p {
  text-align: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.yodlee-frame > div.frame {
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(100vh - 175px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.yodlee-frame h3 {
  margin: 2px 0;
}

.yodlee-frame ul {
  margin: 2px 0;
}

.link-account iframe {
  width: 100%;
  height: 99%;
  border: none;
  overflow: scroll;
}

.link-account h6 {
  padding: 0;
  margin: 0;
}

.page-head h1 {
  margin-top: 6px;
}

.manual-link-url {
  text-align: center;
  display: block;
  margin: 0;
}

.manual-link-url.top {
  text-align: right;
  font-size: 12px;
  margin-top: 15px;
}

.pre-loader {
  text-align: center;
}

.main.pre-loader.aop p{
  height: 50px !important;
}

.page {
  animation: 400ms fade-in;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

select::-ms-expand {
    display: none;
}

.confirmation dl {
  display: flex;
}

.confirmation dt {
  flex: 2;
  font-weight: bold;
  min-width: 45px;
}

.confirmation dd {
  margin-left: 10px;
  flex: 5;
  color: #5e9edc;
}

.transfer-method {
  padding-bottom: 3px;
}

button, .btn {
  -webkit-appearance: none;
  border: none;
  padding: 8px 30px;
  color: rgba(255, 255, 255, .84);
  font-size: 14px;
  font-weight: 500;
  outline: 0;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
  text-transform: uppercase;
}

a.btn {
  display: inline-block;
  text-decoration: none;
  width: auto;
  box-sizing: border-box;
  margin: 0 auto;
}

.landing div.ripple > * {
  width: 100%;
}

.primary {
  text-transform: uppercase;
  background-color: #0077BF;
}

.primary:hover {
  background-color: #1188CF;
}

.success {
  background-color: #3d8d00;
}

.success:hover {
  background-color: #479c00;
}

.danger {
  background-color: #d65258;
}

.danger:hover {
  background-color: #6d757d;
}

.default {
  background-color: #eee;
  color: black;
}

.default:hover {
  background-color: #e4e4e4;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .25);
}

.modal > div {
  position: fixed;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 20px;
  animation: 200ms fade-in;
  min-width: 245px;
}

.modal > div .actions {
  justify-content: center;
}

.client-center form section {
  width: 70%;
}

.client-center .acats form section {
  width: 100%;
}

form.confirmation {
  font-size: 10pt;
}

.client-center form.confirmation {
  font-size: 12pt;
}
.aop .h1, .aop .h2, .aop .h3, .aop .h4, .aop h1, .aop h2, .aop h3, .aop h4, .aop h5, .aop h6{
  color: #454545;
  font-family: "Hind", sans-serif;
}
.aop .error-bar{
  position: absolute;
  width: 100%;
  top: 0px;
  border-radius: 4px;
  box-shadow: 1px 1px 18px -9px #000;
  padding: 5px;
}

.aop div.page-head h1, .aop div.page-head h6 {
  text-align: center;
}

.aop .thank-you h3{
  font-size: 12pt !important;
  color: #6C6C6C !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
  margin: 0;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.aop .thank-you p{
  color: #7A7A7A !important;
  margin: 0;
  margin-bottom: 20px !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

/* Update available-cash styles */
.available-cash {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #0089cf !important;
  text-align: center;
  margin-top: 0px;
  margin-bottom: auto;
}


.fast-cash-image {
  width: 100px; 
  height: 100px;
  display: block;
  margin: 0 auto 0 auto;
  fill: #0089cf; 
}



.main.aop h2 {
  text-align: center;
  font-size: 28px;
  color: #333;
}

.thank-you-page {
  padding-top: 75px;
}


.instant-cash-container {
  text-align: center;
  margin: 0 auto;
}

.main.aop .message {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;
}

.instant-cash-title{
  margin-top: auto;
  margin-bottom: auto;
}

.main.aop span{
  display: block;
}

.aop div.page-head h1, .aop div.page-head h6 {   text-align: center; } 

@media (max-width: 749px) {
  .aop div.page-head h1{
    font-size: 16pt;
  }
}

@media (max-width: 500px) {
  .aop .thank-you h3{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .aop .thank-you p{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

ul#navigation {
  list-style: none;
  max-width: 280px;
  margin: 0 auto;
  padding: 0;
}

ul#navigation li div {
  width: 100%;
}

ul#navigation li {
  text-align: center;
  margin-top: 8px;
}

button.success:disabled {
  opacity: 0.7;
}

button.success:disabled:after {
  content: '\f021';
  font-family: 'FontAwesome';
  position: absolute;
  margin: 0 5px;
  top: 20%;
  color: #fff;
  font-size: 20px;
  animation: spin 1s linear infinite;
}

.modal button.success:disabled {
  white-space: nowrap;
}

.modal button.success:disabled:after {
  display: inline-block;
  position: initial;
  margin: 2px 5px;
  font-size: 14px;
}

.tango-default .page-head h1 {
  display: none;
}

.tango-default .page-head h6 {
  margin: 15px 0;
}

@media print {
  @page {
    size: portrait;
  }

  div.main {
    max-width: auto;
    min-width: 100%;
    margin: 0;
  }

  form section {
    min-width: 100%;
  }

  body * {
    color: #000 !important;
    border-color: #e0dddc !important;
    background-color: transparent !important;
    font-size: 10pt !important;
  }

  .navigation-menu {
    display: none;
  }

  button {
    display: none;
  }

  .confirmation dd {
    flex: 1;
  }
}

#donut {
  margin-bottom: 0px;
}

#metrics {
  margin-left: -15%;
  font-size: 15px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.atw {
  border-top: #000 solid 2px;
}

.atw-amounts {
  text-align: right;
  color: #009EFA;
}

td {
  padding: 5px;
  font-size: 14px;
}

div {
  margin-bottom: 15px;
}

.amount-validation {
  margin-left: 8px;
  margin-top: -30px;
  position: absolute;
  color: #d9534f;
  font-size: 10px;
  font-weight: 500;
}

.last-update {
  text-align: right;
  color: #AD9BA4;
  padding-top: 0px;
}

.result {
  font-weight: bold;
  font-size: 14px;
}

#balance-details td{
  width: 60%;
}

select:focus::-ms-value {
  background: none;
  color:#000;
}

#donut {
  height: 150px;
}

/* DatePicker */
.react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 0px;
}
.react-datepicker__input-container {
  width: 100%;
  margin-bottom: 0px !important;
}
.react-datepicker {
  font-size: 1em;
  /* height: 270px !important; */
}

.react-datepicker__week {
  margin-bottom: 5px !important;
}

.react-datepicker__header {
  /* padding-top: 0.8em; */
}
.react-datepicker__month {
  /* margin: 0.8em; */
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.8em;
  /* line-height: 1.9em; */
  /* margin: 0.166em; */
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-popper {
  margin-bottom: 0px !important;
  /* position: absolute; */
  /* will-change: transform; */
  /* top: 0px; */
  /* left: 0px; */
  /* transform: translate3d(0px, 38px, 0px) !important; */
}

#scheduled-date-label {
  font-size: 12px;
  padding: 0 11px;
}

#calendar {
  margin-bottom: 0px;
}

.datepicker-calendar {
  margin-bottom: 0px !important;
}

.react-datepicker__header__dropdown {
  margin-bottom: 0px !important;
}

.main.aop .actions{
  display: block;
  height: 40px;
}
.main.aop .manage-account-actions > div ,.main.aop .actions > div{
  width: auto;
}
.main.aop .manage-account-actions .ripple , .main.aop .actions .ripple{
  float: right;
}
.main.aop button{
  font-size: 14px;
}
.main.aop .ripple{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 18px;
  margin-left: 5px;
  margin-right: 5px;
}

.main.aop .ripple:focus{
  -webkit-box-shadow: 0 0 8px rgba(0,0,0,.18), 0 8px 16px rgba(0,0,0,.36);
  box-shadow: 0 0 8px rgba(0,0,0,.18), 0 8px 16px rgba(0,0,0,.36);
}
.main.aop .primary{
  background-color: #0089cf;
  color: #ffffff;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  border: 1px solid #0089cf;
  border-radius: 18px;
}
.main.aop .success{
  background-color: #0089cf;
  color: #ffffff;
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 18px;
}
 .success:hover, .success:focus{
  color: #fff;
}

.main.aop .primary:hover{
  background-color: #268BC9;
}
.main.aop .default {
  background-color: #ffffff;
  color: #0089cf;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  border: 1px solid #0089cf;
  border-radius: 18px;
}
.main.aop .default:hover{
  background-color: #f0f0f0;
}
.main.aop img[data-tip] {
    width: 20px;
    height: 20px;
    margin-top: 15px;
}
.main.aop .__react_component_tooltip{
  background-color: rgba(152,229,218,0.9) !important;
  color: #1a1a1a !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.2em !important;
}
.main.aop .__react_component_tooltip.type-dark.place-left:after{
  border-left-color:  rgba(152,229,218,0.9);
}
.main.aop .react-datepicker .react-datepicker__navigation{
  box-shadow: none; 
  -webkit-box-shadow: none;
}

.scheduled-actions{
  padding: 2%
}


.customExpandButton {
  float: right;
  margin-top: 2px;
  margin-left: 3px;
}

.expanded {
  animation-name: toExpanded;
  animation-duration: 1s;
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

.collapsed {
  color: cornflowerblue;
  animation-name: toCollapsed;
  animation-duration: 1s;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.customHeaderMenuButton,
.customHeaderLabel,
.customHeaderLabel,
.customSortDownLabel,
.customSortUpLabel,
.customSortRemoveLabel {
  margin-top: 2px;
  margin-left: 4px;
  float: left;
}

.customSortDownLabel {
  margin-left: 10px;
}

.customSortUpLabel {
  margin-left: 1px;
}

.customSortRemoveLabel {
  float: left;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}

@keyframes toExpanded {
  from {
    color: cornflowerblue;
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
  to {
    color: black;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
}

@keyframes toCollapsed {
  from {
    color: black;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
  to {
    color: cornflowerblue;
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
}


/* Collapsible */
/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: 1px solid black;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0px;
  /* display: none; */
  /* overflow: hidden; */
  /* background-color: #f1f1f1; */
}

.hide-content {
  padding: 0px;
  display: none;
}

.collapsible:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
}

.collapsible.active:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
}

.ag-cell-focus,.ag-cell-no-focus {
  border: none !important;
  }
  .ag-cell:focus {
  border: none !important;
  outline: none !important;
  }

.ag-header.ag-pivot-off {
  display: none !important;
}

.transfer-history div {
  margin-bottom: 0px !important;
}

.transfers-period.dropdown.field {
  width: 50%;
  margin-left: 50%;
}

.ag-paging-button {
  background-color: rgb(238,238,238) !important;
  background-size: 20px 20px !important;
  height: 20px !important;
  width: 20px !important;
  overflow: inherit !important;
}

.ag-paging-button:hover {
  background-color: #bdc3c6 !important;
}

.ag-paging-button[disabled] {
  background-color: transparent !important;
}

@media screen and (max-width: 320px) {
  .ag-paging-button {
    background-size: 15px 15px !important;
    height: 15px !important;
    width: 15px !important;
  }
}

.tooltip-mobile {
  width: 86% !important;
  left: 40px !important;
}

@media screen and (max-width: 420px) {
  .__react_component_tooltip.place-top:after {
    margin-left: 38% !important;
  }
}

.main.aop .field label {
  font-size: 13px;
  font-weight: 400;
  font-family: Montserrat;
  color: #58595B;
}

.main.aop .field input, .main.aop .field select {
  color: #58595b;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 15px !important;
}

.main.aop .fee-schedule dd {
  color: #0089cf;
}
  
.main.aop .confirmation dd {
  color: #0089cf !important;
}

.aop .page-head h1{
  color: #150958;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.aop .thank-you .page-head h1{
  color: #150958;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.main.aop section#funding-methods h4{
  color: #0089cf !important;
}

.main.aop h6 {
  font-size: 13px;
  font-weight: 400;
  font-family: "Hind", sans-serif;
  color: #58595B;
}

.main.aop .yodlee-frame .frame{
  height: 550px !important;
}

@media (max-width:392px){
  .main.aop .actions .ripple{
    float: none !important;
    width: 100% !important;
    position: absolute;
    margin:auto;
    margin-top: inherit;
  }

  .main.aop .yodlee-frame .frame{
    height: 450px !important;
  }

  .main.aop .actions{
    position: relative;
    height: auto;
  }

  .main.aop a{
    text-align: center;
  }

  .aop p{
    text-align: center;
  }

  .aop h3{
    text-align: center;
  }

  #funding-methods p{
    text-align: left;
  }
}

.main.aop section#wire-deposit-equities div.instruction-block h5, section#wire-deposit-futures div.instruction-block h5{
  font-size: 12px !important;
}