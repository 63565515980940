.error-bar {
  opacity: 0;
  height: 0;
  padding: 0;
  margin-top: 0;
  text-align: center;
  color: white;
  background-color: #f44336;
  transition: all 400ms;
}

.error-bar.active {
  opacity: 1;
  height: initial;
  padding: 5px;
  margin-top: 15px;
}

.error-bar ul {
  text-align: left;
  margin: 10px auto;
}

@media only screen and (min-width : 414px)  {
  .error-bar {
    font-size: 1.25em;
  }
  .error-bar br {
    display: none;
  }
}
