div.wrapper {
  display: -webkit-flex;
}

div.wrapper > div {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

#disclaimer-accept {
  margin-left: 120px;
}


@media only screen and (max-width: 400px) {
  div.wrapper {
    display: table;
    margin: auto;
  }

  #disclaimer-accept {
    margin-top: 20px;
    margin-left: 0px;
  }
}