.logout{
    float: right;
    position: relative;
  }
  
  .account-nav{
    padding-top: 10px;
    position: relative;
    float: initial;
  }
