section#wire-deposit-equities dl,
section#wire-deposit-futures dl {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

section#wire-deposit-equities dt,
section#wire-deposit-futures dt {
  font-weight: bold;
  flex: 1 1 50%;
  margin: 0.25em 0;
}

section#wire-deposit-equities dt#deposit-amount,
section#wire-deposit-futures dt#deposit-amount {
  width: 100%;
}

section#wire-deposit-equities dt span.amount,
section#wire-deposit-futures dt span.amount {
  color: #3d8d00;
  font-weight: bold;
  display: inline;
}

section#wire-deposit-equities dd,
section#wire-deposit-futures dd {
  color: #0077BF;
  flex: 1 1 50%;
  margin: 0.25em 0;
}

section#wire-deposit-equities dd.description,
section#wire-deposit-futures dd.description {
  font-size: 8pt;
  color: rgba(102, 102, 102, 0.7);
}

section#wire-deposit-equities h4,
section#wire-deposit-futures h4 {
  text-align: center;
  margin-bottom: 0;
}

section#wire-deposit-equities p,
section#wire-deposit-futures p {
  margin-top: 0.5em;
}

section#wire-deposit-equities div.instruction-block h5,
section#wire-deposit-futures div.instruction-block h5 {
  color: white;
  background: #0077BF;
  padding: 0.5em;
  outline: 1px solid black;
  margin: 1em 0;
  font-size: .85rem;
}

@media print {
  div.instruction-block dl {
    border: 1px solid #e0dddc;
    padding: 0.5em;
  }

  section#wire-deposit-equities dt:empty,
  section#wire-deposit-futures dt:empty {
    display: none;
  }

  section#wire-deposit-equities dd.description,
  section#wire-deposit-futures dd.description {
    font-size: 8pt;
    margin-top: 1em;
    text-transform: uppercase;
    border: none;
    text-align: center;
    flex: 2 1 100%;
    margin: 0 0 1em 0;
  }
}
