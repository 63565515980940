.manage-accounts {
  font-size: 14px;
}

.manage-accounts table {
  width: 100%;
  border: 1px solid #e0dddc;
  padding: 8px 4px;
  border-spacing: 0;
  border-collapse: collapse;
}

.manage-accounts th,
.manage-accounts td {
  position: relative;
  text-align: left;
  padding: 5px;
  border-right: 1px solid #e0dddc;
}

.manage-accounts th:last-child::after,
.manage-accounts td:last-child::after {
  display: none;
}

.manage-accounts a {
  color: #d9534f;
  text-decoration: underline;
  text-transform: capitalize;
}

.manage-accounts table button {
  font-size: 12px;
  padding: 4px 12px;
  text-transform: capitalize;
}

.manage-account-actions {
  margin: 30px 0;
  text-align: center;
}

.manage-accounts ul {
  margin: 0;
  padding: 0;
}

.manage-accounts li {
  position: relative;
  list-style: none;
  border: 1px solid #e0dddc;
  border-top: none;
  padding: 4px 8px;
}

.manage-accounts li:first-child {
  border-top: 1px solid #e0dddc;
}

.manage-accounts li a {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px;
}
