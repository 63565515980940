.client-center .address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.client-center .address div:first-child {
  width: 40%;
}

.client-center .address div:nth-child(2),
.client-center .address div:nth-child(3) {
  width: 25%;
}

.intermediary {
  text-align: center;
}

#intermediary-information h6 {
  font-weight: 300;
  font-size: 12px;
  margin: 0px 0px 15px 0px;
}

@media (max-width: 700px) {
  .client-center .address div:first-child,
  .client-center .address div:nth-child(3),
  .client-center .address div:nth-child(2) {
    width: 100%;
  }
}
