.withhold-amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.withhold-amount > * {
  flex: 1;
}

.withhold-amount > span {
  margin: 5px;
  flex: none;
}
