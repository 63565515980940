.navigation-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 15px 0 5px;
  font-size: 12px;
  z-index: 10;
}

.navigation-menu a {
  display: block;
  text-align: center;
  flex: 1;
  border: 1px solid #e0e0e0;
  border-right: none;
  padding: 8px;
  text-decoration: none;
}

.navigation-menu a:last-child {
  border-right: 1px solid #e0e0e0;
}

.navigation-menu a.active {
  background-color: #0077BF;
  color: white;
}

@media screen and (max-width: 320px) {
  .navigation-menu a {
    font-size: 11px;
  }
}
