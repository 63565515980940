.modal.show{
	display: block !important;
    background-color: rgba(black, 0.8) !important;
    z-index: 9999999;
}
.modal > div{
    padding: 0px 20px 20px !important;
    border-radius: 5px; 
    border: 1px solid #616161;
}
.modal-content {
    margin: 0px; 
}

.modal-header {
    margin-bottom: 0px;
    padding: 10px 10px 0 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.modal-body {
    text-align: center;
    padding: 0 10px 0 10px;
    min-width: 100px;
    width: 400px;
}

.modal-footer {
    padding: 0px 13px;    
    margin: auto;
}
.modal-footer button {
    color: #0077BF;
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.modal-footer button {
    text-align: center;
    color: #0077BF;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    width: 46%;
}
.btn-cancel {
    float: left;
}
.btn-confirm {
    float: right;
}
.btn-centered {
    margin-left: 25%;
    margin-bottom: 10px;
}