div.request-history {
  font-size: 14px;
}

@media screen and (max-width: 350px) {
  div.request-history {
    font-size: 12.5px;
  }
}

div.request-history div.title,
div.request-history ul {
  width: 100%;
  /* border: 1px solid #e0dddc; */
  box-sizing: border-box;
  overflow: auto;
}

div.request-history div.transactions {
  border: 1px solid #e0dddc;
}

div.request-history ul {
  list-style: none;
  padding: 0.2em;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border-top: none;
}

div.request-history ul li {
  display: block;
  width: 33.33%;
  text-align: center;
}

div.request-history ul:first-child {
  border-top: 1px solid #e0dddc;
}

div.request-history ul li.small {
  font-size: 8pt;
}

@media screen and (max-width: 350px) {
  div.request-history ul li.small {
    font-size: 6.5pt;
  }
}

div.request-history ul li.request-actions {
  text-align: center;
  /* display: none; */
}

div.request-history ul li.transfer-actions {
  text-align: center;
  display: none;
}

div.request-history ul li.request-actions .material-icons { color: #000; }

div.request-history ul li.request-actions button {
  font-size: 12px;
  padding: 4px 12px;
  text-transform: capitalize;
}

div.request-history ul li.request-actions button:hover { background: #ccc; }

table.request-history {
  font-size: 14px;
}

table.request-history {
  width: 100%;
  border: 1px solid #e0dddc;
  padding: 8px 4px;
  margin-bottom: 2em;
}

table.request-history caption {
  background: #EEE;
  text-align: left;
  font-size: 12pt;
  font-weight: bold;
  padding: 0.2em 0.5em;
  border: 1px solid #e0dddc;
}

table.request-history caption > i {
  font-size: 14pt;
  margin-right: 0.3em;
}

table.request-history th,
table.request-history td {
  position: relative;
  text-align: left;
}

table.request-history th::after,
table.request-history td::after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  border-right: 1px solid #e0dddc;
  height: 28px;
  margin: 0 5px;
}

table.request-history th:last-child::after,
table.request-history td:last-child::after {
  display: none;
}

table.request-history td > i {
  display: none;
}

table.request-history button {
  font-size: 12px;
  padding: 4px 12px;
  text-transform: capitalize;
}

table.request-history td.request-actions,
table.request-history th.request-actions {
  display: none;
}

table.request-history .currency-amount {
  text-align: right;
  padding-right: 16px;
}

tbody.empty-transactions td {
  width: 100%;
  text-align: center;
  padding: 2em;
}

.request-history .declined {
  color: #d9534f;
  text-decoration: underline;
}
